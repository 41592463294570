.section-header{
	position: relative;
	margin-bottom: 0px;
	margin-top: 80px;
}
.section-header .number{
	margin: 0px;
	font-family: Inter;
	font-style: normal;
	font-weight: 800;
	font-size: 100px;
	text-transform: uppercase;
	color: rgba(0, 0, 0, 0.1);
    position: relative;
    left: -3px;
}
.section-header .title{
	margin: 0;
	position: absolute;
	bottom: 18px;
	left: 0px;
	font-family: Inter;
	font-style: normal;
	font-weight: bold;
	font-size: 28px;
	color: #333333;
}
@media(max-width: 767px){
	.section-header{
		margin-top: 40px;
	}
	.section-header .number{
		font-size: 80px;
	}
	.section-header .title{
		font-size: 24px;
		bottom: 13px;
	}
}