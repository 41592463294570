/*@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600;700&family=Inter:wght@400;700&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700;800');

body{
	font-family: 'Inter';
	font-weight: 400;
    letter-spacing: -0.2px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}
a{
	text-decoration: none !important;
	outline: none;
	-webkit-tap-highlight-color: transparent;
}
a.normal-link{
	color: #2087da;
	position: relative;
	transition: all 0.1s ease-in;
}
a.normal-link:hover{
	opacity: 0.7;
}
a.normal-link:after{
	content: '';
	background-color: #2087da;
	opacity: 0.5;
	height: 1px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	position: absolute;
}
h1, h2, h3, h4, h5, h6{
	font-family: 'Inter';
	font-weight: 400;	
}
h1{
	font-family: Inter;
	font-style: normal;
	font-weight: 800;
	font-size: 36px;
	line-height: 140%;
	/*letter-spacing: -0.01em;*/
	color: #333333;
}
h2{
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 28px;
	line-height: 100%;
	letter-spacing: -0.02em;
	color: #333333;
}
.noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none; 
	-moz-user-select: none; 
	-ms-user-select: none; 
	user-select: none; 
}
.position-relative{
	position: relative;
}
.interactive-link{
	position: relative;
	display: inline-block;
	margin-left: 10px;
	cursor: pointer;
	z-index: 2;
	color: #2087da;
	transition: all 0.1s ease-in;
}
.interactive-link:hover{
	opacity: 0.8;
}
.interactive-link:after{
    position: absolute;
    content: '';
    left: 0px;
    right: 0px;
    bottom: 3px;
    border-bottom: 4px solid #c2dbef;
    border-radius: 100px;
    z-index: -1;
}
.popover{
	position: absolute;
	width: 200px;
	background-color: #fff;
	box-shadow: 0px 0px 30px rgba(0,0,0,0.08);
	top: calc(100% + 0px);
	left: 10px;
	/*right: 0px;*/
	padding: 15px 10px;
	border-radius: 8px;
	z-index: 1;
}
.popover p{
	font-size: 18px;
}
.btn{
	padding: 15px 20px;
	font-size: 15px;
	font-weight: 700;
	text-transform: uppercase;
	display: inline-block;
	border-radius: 6px;
	cursor: pointer;
	font-family: 'Inter';
	font-weight: 800;
	letter-spacing: 0px;
}
.btn-primary{
	background-color: #FFCD02;
	color: #333;
}
.container, .container-md, .container-sm {
    max-width: 680px;
}
.card-outline{
	border-radius: 8px;
	border: 1px solid #ddd;
}
.height-auto{
	height: auto !important;
	min-height: unset !important;
}
.container-bleeding{
	width: 1200px;
	margin-left: auto;
	margin-right: auto;
}
.align-center{
	text-align: center;
}
@media(max-width: 767px){
	h1{
		font-size: 28px;
	}
	.container, .container-bleeding{
		padding-left: 25px;
		padding-right: 25px;
	}
	.container-bleeding{
		width: 100%;
	}
	.mob-center{
		text-align: center;
	}
	h2{
		font-size: 21px;
	}
	.web-only{
		display: none !important;
	}
}
@media (min-width: 768px){
	.container, .container-lg, .container-md, .container-sm, .container-xl {
	    max-width: 1140px;
	}
	.web-only{
		display: block;
	}
}