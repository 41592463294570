.footer{
	margin-top: 40px;
	margin-bottom: 60px;
}
.footer-title{
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 22px;
	color: #333;
	text-align: center;
}
.navbar-link-container{
	text-align: right;
}
.navbar-link{
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #333;
	border-radius: 1000px;
	padding: 4px 10px;
	margin: 0px 5px;
	display: inline-block;
	cursor: pointer;
	background-color: transparent;
	transition: all 0.2s ease-in;
}
.footer .navbar-link-container{
	text-align: center;
}
.footer .navbar-link{
	margin: 0px;
}
.navbar-link img{
	height: 32px;
	opacity: 0.5;
	transition: all 0.1s ease-in;
}
.navbar-link:visited, .navbar-link:hover{
	color: #333;
}
.navbar-link:hover img{
	opacity: 0.9;
}
.navbar-link.active{
	background-color: #eee;
	color: #333;
}
.footer-seal-container{
	height: 140px;
	width: 140px;
	margin-bottom: 60px;
	margin-left: auto;
	margin-right: auto;
	display: block;
    position: relative;
}
.footer-seal{
	height: 140px;
	width: 140px;
    animation: spin 20s infinite linear;
    position: relative;
}
.footer-seal-heart{
    position: absolute;
    height: 28px;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    display: block;
    margin: auto;
}
@keyframes spin {
    0%  {-webkit-transform: rotate(0deg);}
    100% {-webkit-transform: rotate(360deg);}   
}
@media(max-width: 768px){
	.footer {
	    margin-top: 20px;
	    margin-bottom: 30px;
	}
	.footer-seal-container{
		height: 110px;
		width: 110px;
		margin-bottom: 40px;
	}
	.footer-seal{
		height: 110px;
		width: 110px;	
	}
	.footer-seal-heart{
		height: 21px;
	}
	.footer-title{
		font-size: 15px;
		margin-bottom: 20px;
	}
}