.blur{
	display: block !important;
}
.lazy-load-image-background.blur.lazy-load-image-loaded > img{
	max-width: 100%;
}
.bleeding-content{
    margin-left: -33.33%;
    margin-right: -33.33%;
    margin-top: 50px;
    margin-bottom: 60px;
}
.bleeding-content img{
	width: 1200px;
	display: block;
	margin: auto;
	border-radius: 8px;
	box-shadow: 0px 2px 6px rgba(0,0,0,0.03);
}
.cover-bg.blur{
    filter: blur(0px) !important;
    width: 1200px;
    margin: 0 auto;
}
.cover-bg, .cover-bg img{
	max-width: 1200px;
	height: 700px;
	border-radius: 8px;
	background-color: #f0f0f0;
}
article .normal-image{
	width: 100%;
	margin: 30px 0px 50px 0px;
	border-radius: 8px;
}
article h3{
	font-family: Inter;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 130%;
	color: #333333;
	letter-spacing: -0.2px;
}
article p, article .intro-text{
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 19px;
	line-height: 180%;
	color: #444;
}
.normal-image.border{
	border: 1px solid #ddd;
}
article{
	position: relative;
}
article ul, article ol{
	padding-left: 17px;
}
article li{
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 19px;
	line-height: 160%;
	color: #666;
	margin-bottom: 5px;
	padding-left: 10px;
}
article hr {
  margin: 50px 0 70px 0;
  border: 0;
  text-align: center;
}
article hr:before {
	content: "\2022   \2022   \2022";
	font-size: 30px;
	color: #aaa;
}
article .quote{
	/*display: flex;*/
}
article .quote p{
	font-size: 32px;
	color: #333;
	line-height: 150%;
	/*font-style: italic;*/
	font-family: 'Inter';
	font-weight: normal;
	/*margin: 0px 50px 0px 50px; */
}
article .quote img{
	width: 64px;
	display: block;
    align-self: flex-start;
	margin-right: 20px;
}
.bleeding-content .container .row img{
	width: 100%;
	border: 1px solid #eee;
	border-radius: 8px;
}
.project-details-item-header{
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	text-transform: uppercase;
	color: #888;
}
.project-details-item-desc{
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 140%;
	color: #333333;
}

@media(min-width: 1201px){
	.bleeding-content .container{
	    width: 1200px;
		max-width: 1200px;
	}
}
@media(max-width: 767px){
	article p, article .intro-text, article li{
		font-size: 17px;
		line-height: 160%;
	}
	.project-details-item-desc{
		font-size: 17px;
	}
	.project-details-item-header{
		font-size: 14px;
	}
	.bleeding-content{
		margin: 30px -25px 40px -25px;
	}
	.bleeding-content img{
		width: 100%;
		border-radius: 0px;
	}
	.bleeding-content .container .row img{
		border-radius: 8px;
	}
	article .normal-image {
    	margin: 20px 0px 35px 0px;
	}
	article .quote{
		display: block;
	}
	article .quote img{
		width: 48px;
	}
	article .quote p{
		font-size: 26px;
	}
	article hr{    
		margin: 30px 0 60px;
	}
	article hr:before {
	    font-size: 24px;
	}
	.bleeding-content.cover img{
		max-width: 100% !important;
	}
	.cover-bg.blur{
		width: 100%;
	}
	.cover-bg, .cover-bg img{
		width: 100%;
		height: auto;
	}
}