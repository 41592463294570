.work-card-container{
	border-radius: 8px;
	height: 320px;
	position: relative;
	background: #FFF1E4;
	padding: 40px 50px;
	margin-bottom: 50px;
	box-shadow: none;
	cursor: pointer;
	transition: all 0.2s ease-in;
	top: 0px;
}
.work-card-container:hover{
	/*box-shadow: 0px 10px 20px rgba(0,0,0,0.03);*/
	top: -5px;
}
.work-card-container .header{
	font-family: Inter;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 34px;
	margin-top: 0px;
	margin-bottom: 10px;
	color: #333333;
}
.work-card-container .description{
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 29px;
	color: #555555;
	margin-top: 0px;
}
.work-card-container .preview-image{
	position: absolute;
	right: 1px;
	bottom: 1px;
	height: 273px;
	width: auto !important;
    border-radius: 6px 0px 6px 0px;
}
.work-card-container .role{
	opacity: 1;
	position: absolute;
	bottom: 40px;
	font-family: Inter;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 160%;
	/* or 22px */
	text-transform: uppercase;
	color: rgba(0,0,0,0.6);
	transition: all 0.15s ease-in
}
.work-card-container .role img{
	opacity: 0.6;
	margin-left: 5px;
	width: 18px;
}
/*.work-card-container:hover .role{
	opacity: 1;
}*/
.work-card-container-sm .preview-image{
	width: 220px !important;
	height: 273px;
	object-fit: cover;
	object-position: left;
}

@media(max-width: 767px){
	.work-card-container{
		height: auto;
		padding: 25px 25px 0px 25px;
		border: none;
		margin-bottom: 30px;
	}
	.work-card-container .preview-image{
		position: static;
		width: 100% !important;
		border-radius: 8px 8px 0px 0px;
	    right: 0px;
	    bottom: 0px;
	    height: 160px;
	    object-fit: cover;
	    margin-bottom: -2px;
	    margin-top: 20px;
	    object-position: top;
        box-shadow: none;
	}
	.work-card-container .header{
		font-size: 20px;
	}
	.work-card-container .description {
    	font-size: 16px;
    	line-height: 160%;
	}
	.work-card-container .role {
	    display: block !important;
	    position: static;
	    opacity: 1;
	    color: #555;
	    margin: 12px 0px !important;
	}
	.work-card-container .role img {
		width: 16px;
		position: relative;
		top: 3px;
	}
	.work-card-container:hover{
		top: 0px;
	}
}