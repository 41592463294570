.image-container{
	margin-bottom: 50px;
}
.image-container img{
	width: 1200px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
	border: 0.5px solid #eee;
	border-radius: 8px;
}
.image-caption{
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 160%;
	text-align: center;
	color: #888;
}
@media(max-width: 767px){
	.image-container{
		margin-bottom: 40px;
	}
	.image-container img{
		width: 100%;
		border-radius: 0px;
		border-left: 0px;
		border-right: 0px;
		margin-bottom: 15px;
	}
	.image-caption{
		font-size: 14px;
	}
}