.user-story-card{
	padding: 30px;
	border-radius: 8px;
	margin-bottom: 30px;
	min-height: 213px;
}
.user-story-card.border{
	border: 1px solid #ddd;
}
.user-story-card img{
	height: 48px;
	margin-bottom: 20px;
}
.user-story-card h3{
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	font-weight: 700;
}
.user-story-card p {
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	line-height: 160%;
	color: #555555;
	margin: 0px;
}
article .intro-text img{
	width: 21px;
}
@media(max-width: 767px){
	.user-story-card{
		padding: 20px;
		min-height: unset;
	}
	.user-story-card p {
		font-size: 16px;
	}
	.user-story-card h3{
		font-size: 18px;
	}
}