.navbar{
	margin-top: 50px;
	margin-bottom: 80px;
}
.navbar-title{
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 36px;
	color: #333333;
}
.navbar-link-container{
	text-align: right;
}
.navbar-link{
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #333;
	border-radius: 1000px;
	padding: 4px 20px;
	margin: 0px 10px;
	display: inline-block;
	cursor: pointer;
	background-color: transparent;
	transition: all 0.2s ease-in;
}
.navbar-link:visited, .navbar-link:hover{
	color: #333;
}
.navbar-link:hover{
	background-color: #fafafa;
}
.navbar-link.active{
	background-color: #eee;
	color: #333;
}
@media(max-width: 767px){
	.navbar{
		margin: 15px 0px 50px 0px;
	}
	.navbar .container{
		padding-left: 10px !important;
		padding-right: 10px !important;
	}
	.navbar-title{
		font-size: 20px;
		text-align: center;
		display: block;
		margin-bottom: 10px;
	}
	.navbar-link{
		font-size: 15px !important;
		/*margin: px !important;*/
	}
	.navbar-link-container{
		text-align: center !important;
	}
}
@media(min-width: 768px) and (max-width: 1024px){
	.navbar{
		margin: 20px 0px 60px 0px;
	}
	.navbar-title{
		text-align: left;
	}
}