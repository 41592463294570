.dp-main-container{
	position: relative;
	margin-bottom: 30px;
	cursor: pointer;
	width: 170px;
	margin-right: 30px;
}
.dp-main{
	width: 170px;
}
.dp-mask{
    position: absolute;
    left: 42px;
    bottom: 92px;
    height: 61px;
    transition: all 0.2s ease-in;
    opacity: 1;
}
.dp-main-container:hover .dp-mask{
	opacity: 0;
	bottom: 58px;
}
.intro-text img{
	width: 26px;
	margin-right: 15px;
	position: relative;
	top: 3px;
}
.intro-text{
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 160%;
	color: #444;
}
.intro-text-2{
	line-height: 170%;
	font-size: 18px;
}
.work-cards-container{
	margin-top: 50px;
}
.waving-hand{
  	animation-name: wave-animation;
  	animation-duration: 2.5s;        
  	animation-iteration-count: infinite;  
  	transform-origin: 70% 70%;       
  	display: inline-block;
  	height: 44px;
  	position: relative;
  	top: 5px;
}
@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(14.0deg) } 
   20% { transform: rotate(-8.0deg) }
   30% { transform: rotate(14.0deg) }
   40% { transform: rotate(-4.0deg) }
   50% { transform: rotate(10.0deg) }
   60% { transform: rotate( 0.0deg) }
  100% { transform: rotate( 0.0deg) }
}
.popover-fullstack{
	width: 100%;
	padding: 20px 15px;
	z-index: 10;
}
.dictionary-speech-icon{
	height: 36px;
}
.popover-fullstack h4{
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 100%;
	color: #333333;
    letter-spacing: -0.25px;
}
.popover-fullstack h5{
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	line-height: 100%;
	color: #999999;
    letter-spacing: -0.25px;
}
.popover-fullstack h6{
	font-family: Inter;
	font-style: italic;
	font-weight: normal;
	font-size: 16px;
	line-height: 100%;
	color: #333333;
    letter-spacing: 0;
    margin-left: -52px;
}
.popover-fullstack p{
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	letter-spacing: 0;
	font-size: 16px;
	line-height: 140%;
	color: #666666;
}
.popover-fullstack p img{
	height: 20px;
	margin-left: 5px;
	position: relative;
	top: 3px;
}
.popover-footer{
	margin: 0px -15px -20px -15px;
	border-top: 1px solid #ddd; 
	padding: 10px 20px;
}
.main-header{
	font-family: Inter;
	font-style: normal;
	font-weight: 800;
	font-size: 36px;
	line-height: 140%;
	letter-spacing: -0.01em;
	/*letter-spacing: 0em;*/
	color: #333333;
	margin-top: 8px;
}
.home-intro-container{
	display: flex;
	margin-top: 20px;
}
@media(max-width: 767px){
	.interactive-link{
		margin-left: 0px;
	}
	.dp-main-container{
		margin-top: 10px;
		margin-left: auto;
		margin-right: auto;
	}
	.intro-text {
        font-size: 17px;
	}
	.intro-text img{
		width: 24px;
	}
	.work-cards-container{
		margin-top: 50px;
	}
	.intro-text-2{
		/*line-height: 160%;*/
	}
	.main-header{
		font-size: 28px;
	}
	.home-intro-container{
		display: block;
		margin-top: 0px;
	}
}