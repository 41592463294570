.blog-card{
	display: flex;
	margin-bottom: 60px;
}
.blog-card-thumb{
	width: 140px;
	height: 140px;
	object-fit: cover;
	flex-shrink: 0;
	flex-grow: 0;
	margin-right: 30px;
}
.blog-card-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}
.blog-card .header{
	font-family: Inter;
	font-style: normal;
	font-weight: bold;
	font-size: 21px;
	line-height: 140%;
	margin-top: 0px;
	margin-bottom: 10px;
	color: #333333;
}
.blog-card .description{
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	line-height: 160%;
	color: #555555;
	margin-top: 0px;
/*	height: 1.2em;
	overflow: hidden;*/
}
.publication-logo{
    height: 28px;
    flex-grow: 0;
    flex-shrink: 0;
}
@media(max-width: 767px){
	.blog-card-thumb {
		display: none;
	}
	.blog-card{
		margin-bottom: 50px;
	}
}