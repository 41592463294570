.timeline-container{
	position: relative;
}
.timeline-item{
	height: 70px;
	display: flex;
	flex-direction: row;
	margin-bottom: 40px;
	position: relative;
}
.timeline-item:before{
	content: '';
	position: absolute;
	left: 115px;
	top: 100%;
	height: 40px;
	width: 1px;
	background-color: #ddd;
}
.timeline-item:last-of-type:before {
	display: none;
}
.timeline-year{
	font-size: 20px;
	font-weight: bold;
	color: #333;
	width: 50px;
	margin-right: 30px;
}
.timeline-icon{
	width: 70px;
	height: 70px;
	background-color: #fff;
	/*border: 1px solid #ddd;*/
	border-radius: 1000px;
	margin-right: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 0;
    flex-shrink: 0;
}
.timeline-icon-1{
	background-color: #FFF1E4;
}
.timeline-icon-2{
	background-color: #E4FAFF;
}
.timeline-icon-3{
	background-color: #EAE4FF;
}
.timeline-icon-4{
	background-color: #FFF1E4;
}
.timeline-icon-5{
	background-color: #FFE4E4;
}
.timeline-icon img{
	height: 38px;
}
.timeline-content h5{
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 100%;
	color: #333;
	margin: 0 0 12px 0;
}
.timeline-content p{
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 100%;
	text-transform: uppercase;
	color: #888888;
	margin: 0;
}
.about-full-image, .about-full-image img{
	border-radius: 8px;
	width: 100%;
}
.about-image-container{
	position: relative;
}
.easter-egg-text{
	opacity: 0;
	position: absolute;
	top: calc(100% + 13px);
	font-size: 14px;
	color: #777;
	left: 0px;
	right: 0px;
	text-align: center;
	margin: 0px;
    transition: all 0.1s ease-in;
}
.about-image-container:hover .easter-egg-text{
	opacity: 1;
	top: calc(100% + 6px);
}
.about-easter-egg{
	opacity: 0;
	position: absolute;
    height: 98px;
    left: 48px;
    top: 25px;
    transition: all 0.1s ease-in;
}
.about-image-container:hover .about-easter-egg{
	opacity: 1;
    top: 15px;
}
.timeline-icon-current:before{
	position: absolute;
	content: '';
	width: 70px;
	height: 70px;
	background-color: #fff;
	border-radius: 1000px;
	background-color: #FFE4E4;
	z-index: -1;
	animation: scaleIn 1s infinite;
}
@keyframes scaleIn {
  from {
    transform: scale(1, 1);
    opacity: 1;
  }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0;
  }
}
@media(max-width: 767px){
	.timeline-year{
		font-size: 16px;
		margin-right: 15px;
		flex-grow: 0;
		flex-shrink: 0;
		width: 38px;
	}
	.timeline-icon{
		height: 56px;
		width: 56px;
		margin-right: 15px;
	}
	.timeline-icon-current:before{
		width: 52px;
		height: 52px;
	}
	.timeline-icon img {
 	   height: 28px;
	}
	.timeline-item:before{
		left: 79px;
	}
	.timeline-item{
		height: auto;
	}
	.timeline-content p{
		font-size: 14px;
	}
	.timeline-content h5 {
    	font-size: 17px;
	    line-height: 130%;
    	margin: 0 0 7px 0;
	}
	.about-full-image{
		margin-bottom: 30px;
	}
	.easter-egg-text, .about-easter-egg{
		display: none;
	}

}