.popover .navbar-link{
	padding: 10px 20px;
}
.popover .navbar-link img{
	width: 24px;
	margin-right: 10px;
}
.virtual-arrow{
    position: absolute;
    left: -40px;
    top: -40px;
    height: 115px;
}