.user-persona-container{
	height: auto;
	min-height: none;
	border: 1px solid #eee;
	box-shadow: 0px 2px 6px rgba(0,0,0,0.03)
}
.user-story-intro{
	display: flex;
	align-items: center;
	margin: -30px -30px 15px -30px;
	padding: 30px;
	border-radius: 8px 8px 0 0;
	background: #f8f8f8;
	/*background: url('/img/pattern-bg.svg');*/
	background-repeat: repeat;
}
.user-story-intro img{
	border-radius: 1000px;
	width: 100px;
	height: 100px;
	margin: 0 15px 0 0;
	object-fit: cover;
}
.user-story-intro-details{
	display: flex;
	flex-direction: column;
}
.user-story-intro-details h4{
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 120%;
	color: #333;
	margin: 0 0 6px 0;
}
.user-story-intro-details h5{
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 140%;
	color: #555;
	/*color: rgba(255,255,255,0.9);*/
	margin: 0 0 10px 0;
}
.user-story-intro-details h6{
	display: flex;
	align-items: center;
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 100%;
	text-transform: uppercase;
	color: #777;
	/*color: rgba(255,255,255,0.6);*/
	margin: 0;
}
.user-story-intro-details h6 img{
	height: 20px;
	width: 20px;
	opacity: 0.4;
	margin-right: 5px;
}
.user-persona-section{

}
.user-persona-section-header{
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 100%;
	color: #333333;
	margin-bottom: 15px;
}
.user-persona-section p{
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	line-height: 170%;
	color: #555;
}
.user-persona-section-list li{
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	line-height: 170%;
	color: #555;
	margin-bottom: 10px;
	padding-left: 5px;
}


@media(max-width: 767px){
	.user-story-intro img{
	    width: 60px;
    	height: 60px;
	}
	.user-story-intro{
	    align-items: flex-start;
        margin: -20px -20px 15px -20px;
	    padding: 30px 20px;
	}
	.user-story-intro-details h4{
		font-size: 18px;
	}
	.user-story-intro-details h5{
		font-size: 16px;
	}
	.user-story-intro-details h6{
		font-size: 14px;
	}
	.user-persona-section-header{
		font-size: 16px;
	}
	.user-persona-section p{
		font-size: 16px;
	}
	.user-persona-section-list li{
		font-size: 16px;
	}
}